<template>
    <div class="">
        <layout>
            <template v-slot:header>
                <div class="">
                    <div class="ltlservice-mainblock w-100 index p-relative">
                        <v-container class="mb-6">
                            <breadcrumbs :items="breadcrumbs" :large="true" class="my-5" :textcolor="`textwhite`"></breadcrumbs>
                            <h1 class="heading-1 white--text">LTL Shipping</h1>
                            <p class="white--text my-10 d-none d-sm-block">Less-than-truckload shipping or less than load <br/> (LTL) is the shipping of relatively small freight</p>
                            <div class="text-20-60 white--text">Get instant quotes</div>
                            <v-btn v-if="!showexpansion && $vuetify.breakpoint.xs" x-large class="secondary px-10 py-4 mt-5 text-transform-none" @click="showexpansion = !showexpansion" depressed>Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
                            <v-row v-if="showexpansion || !$vuetify.breakpoint.xs" class="mt-5">
                                <v-col cols="12" sm="4" lg="3">
                                    <label class="white--text" for="">Pickup city or postal code</label>
                                    <v-autocomplete
                                        v-model="form.from.geoModel"
                                        :items="form.from.geoItems"
                                        :search-input.sync="form.from.geoSearch"
                                        background-color="#EDF2F9"
                                        outlined
                                        class="mt-2"
                                        hide-no-data
                                        hide-selected
                                        item-text="label"
                                        placeholder="Pickup city"
                                        hide-details
                                        return-object
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" lg="3">
                                    <label class="white--text" for="">Requested loading date</label>
                                    <v-menu
                                        v-model="form.from.datepicker"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="computedDateFormatted"
                                                class="mt-2"
                                                outlined
                                                background-color="#EDF2F9"
                                                placeholder="08/05/2020"
                                                append-icon="mdi-calendar-month"
                                                hide-details
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="form.from.date"
                                            no-title
                                            @input="form.from.datepicker = false"
                                        ></v-date-picker>
                                    </v-menu>

                                </v-col>
                            </v-row>
                            <v-btn  v-if="showexpansion || !$vuetify.breakpoint.xs" x-large class="secondary px-15 py-8 mt-5 text-transform-none" depressed @click="assign_and_redirect">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
                        </v-container>
                    </div>
                    <div class="agentpage-footerblock w-100 pt-16 pb-8 d-none d-md-block">
                        <v-container>
                            <v-list class="transparent mx-auto" flat >
                                <v-list-item-group class="d-flex flex-row align-self-center justify-space-between h-100">
                                    <v-list-item class="pl-0" :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-20-60 d-flex align-center">
                                                <img src="@/assets/icons/svg/star-white-rounded.svg" alt="Expedited shipping Icon" class="mr-5">
                                                <span class="border white--text">Reduces emissions</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-20-60 d-flex align-center">
                                                <img src="@/assets/icons/svg/star-white-rounded.svg" alt="LTL shipping Icon" class="mr-5">
                                                <span class="border white--text">Guaranteed delivery</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-20-60 d-flex align-center">
                                                <img src="@/assets/icons/svg/star-white-rounded.svg" alt="International shipping Icon" class="mr-5">
                                                <span class="border white--text">Cost-effective</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-container>
                    </div>
                    <v-img class="ltlservice-mainimage d-none d-md-block" src="@/assets/icons/services/truck.svg" alt=""></v-img>
                </div>
            </template>
            <template v-slot:main>
                <v-container class="py-16">
                    <how-work :item="how_work"/>
                </v-container>
                <v-container class="py-16">
                    <h1 class="heading-2 mb-10">LTL Shipping Benefits</h1>
                    <v-row>
                        <v-col cols="24" sm="6" lg="3" v-for="item in star_card">
                            <star-card :color="item.bg" :white="item.white">
                                <template v-slot:title>
                                    {{item.title}}
                                </template>
                                <template v-slot:description>
                                    {{item.text}}
                                </template>
                            </star-card>
                        </v-col>
                    </v-row>
                </v-container>
                <base-counter :bgimg="require('@/assets/logo/bg-logo-blue-full.svg')" :items="counter_items">
                    <h1 class="heading-2 white--text mb-10">We have a solid <br/> experience of LTL shipping</h1>
                </base-counter>
                <div class="py-16">
                    <faq-block :data="faq_data"/>
                </div>
                <div class="py-16">
                    <v-container>
                        <h1 class="heading-2">Other Services</h1>
                        <v-row>
                            <v-col cols="12" lg="4" v-for="item in other_services">
                                <service-card :data="item" />
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <div class="contact primary py-16">
                    <v-container class="my-6">
                        <contact-us />
                    </v-container>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
import Layout from '@/components/layouts/BlueTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'
import HowWork from '@/components/pages/HowWork.vue'
import StarCard from '@/components/base/StarCard.vue'
import BaseCounter from '@/components/base/BaseCounter.vue'
import FaqBlock from '@/components/pages/FAQ.vue'
import ServiceCard from '@/components/base/ServiceCard'
import ContactUs from '@/components/pages/ContactUs.vue'

import geodataMixin from '@/mixins/geodataMixin'

export default {
    mixins: [geodataMixin],
    components: {
        Layout,
        Breadcrumbs,
        HowWork,
        StarCard,
        BaseCounter,
        FaqBlock,
        ServiceCard,
        ContactUs
    },
    data: () => ({
        showexpansion: false,
        calculator: {
            zip: '',
            date: new Date().toISOString().substr(0, 10),
            dateFormatted: new Date().toISOString().substr(0, 10),
            datepicker: false,
        },
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'LTL Shipping',
                disabled: true,
            }
        ],
        how_work: {
            title: 'LTL Shipping: <br /> How Does it Work',
            subtitle: 'Sometimes you may need to ship a relatively small freight. For this type of goods, Freight Crew provides the option of less-than-truckload shipping which is best for transporting small freight or when the load doesn’t require the use of an entire trailer',
            list: [
                {
                    icon: require('@/assets/icons/svg/hwork/msg.svg'),
                    text: 'Submit your request and enter the shipment details',
                },
                {
                    icon: require('@/assets/icons/svg/hwork/dir.svg'),
                    text: 'We examine your request and propose several effective solutions',
                },
                {
                    icon: require('@/assets/icons/svg/hwork/truck.svg'),
                    text: 'You choose the most suitable solution and we provide the carrier',
                },
                {
                    icon: require('@/assets/icons/svg/hwork/box.svg'),
                    text: 'Your freight is delivered',

                },
            ]
        },
        star_card: [
            {
                title: 'Cost-effective',
                text: 'You pay only a fraction of the shipping cost, for the space used for your cargo',
                bg: 'bg-transparent'
            },
            {
                title: 'Reduces emissions',
                text: 'If you are concerned about the ecological sustainability of your business LTL shipping is the best way to reduce your carbon footprint',
                bg: 'bg-transparent'
            },
            {
                title: 'Guaranteed delivery',
                text: 'LTL freight is usually loaded onto pallets or crates. So minimizes the chances of loss or damage in comparison to small package shipping',
                bg: 'bg-transparent'
            },
            {
                title: 'Fast delivery',
                text: 'Timely delivery even in the most outlying regions',
                bg: 'bg-blue',
                white: 'white--text'
            },

        ],
        counter_items: [
            {
                quantity: 50,
                unit: 'k',
                text: 'Over 50 k of loyal <br/> clients trust us',
            },
            {
                quantity: 2,
                unit: 'tons',
                text: 'We shipped over 2 675 186 586 <br/> pounds of various freights',
            },
            {
                quantity: 2,
                unit: 'm',
                text: 'We delivered over <br/> 2 millions of LTL shipments',
            },
        ],
        faq_data: {
            title: 'Frequently Asked Questions about LTL Shipping',
            text: 'Please, read the FAQs for the LTL shipping. <br/> If you have any additional questions, don’t hesitate to contact us info@freightcrew.us',
            items: [
                {
                    title: 'How much does LTL shipping cost?',
                    text: 'The overall price of LTL shipping is basing on several factors: weight, density (the dimension and weight of your shipment combined), distance (the further the shipment goes, the more it will cost), linear floor space (a certain amount of linear floor space a single shipment can consume ) and others.'
                },
                {
                    title: 'What are the LTL freight size limitations?',
                    text: 'Typically an LTL freight shipment is too small to fill the entire 48 or 53-foot truck trailer of a full truckload carrier. So LTL shipping is the optimal freight shipping mode for the middle-sized shipments.'
                },
                {
                    title: 'How do track my LTL shipping?',
                    text: 'We allow you to track your shipment via the Online Tracking System. Thus you can always check where your shipment is and get ahead of any problems that may arise.'
                },
            ]
        },
        other_services: [
            {
                img: 'svg/shipping/expedited-blue.svg',
                img_hover: 'svg/shipping/expedited.svg',
                title: 'Expedited Shipping',
                text: 'This transportation service gives your cargo a special shipment priority over others, making the delivery process faster',
                to: 'frontend.services.expedited'

            },
            {
                img: 'svg/shipping/international-blue.svg',
                img_hover: 'svg/shipping/international.svg',
                title: 'International shipping',
                text: 'Cost-effective international freight shipping operating in the United States, Canada, and Mexico',
                to: 'frontend.services.international'

            },
            {
                img: 'svg/shipping/airplane-blue.svg',
                img_hover: 'svg/shipping/airplane.svg',
                title: 'Airfreight',
                text: 'Airfreight shipping services are fast and efficient that makes it so valuable to companies',
                to: 'frontend.services.airfreight'
            }
        ]
    }),
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.calculator.date)
        },
    },
    watch: {
        'calculator.date' (val) {
            this.calculator.dateFormatted = this.formatDate(this.calculator.date)
        },
    },
    methods: {
        assign_and_redirect(){
            this.calculator_assign();
            this.calculator_redirect();
        },
        calculator_assign(){
            this.$store.dispatch('calculator/setForm', {
                form: this.form
            });
        },
        calculator_redirect(){
            this.$router.push({name: 'frontend.calculator'})
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    }
}
</script>

<style lang="scss">
.primary.header{
    position: relative;
    overflow: hidden;
    header{
        z-index: 1;
    }
    .v-btn.v-size--x-large{
        font-weight: 450;
        font-size: 14px;
        line-height: 22px;
        text-transform: none;
    }
    .ltlservice-mainimage{
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
    }
}
</style>
