<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="5">
                <h1 class="heading-2 mb-8" v-html="data.title"></h1>
                <p class="mr-8" v-html="data.text"></p>
                <v-btn x-large class="secondary px-15 py-8 mt-5 text-transform-none" depressed :to="{name: 'frontend.calculator'}">Get quotes</v-btn>
            </v-col>
            <v-col cols="12" lg="7">
                <extension :items="data.items" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Extension from '@/components/base/Extension.vue'
export default {
    name: 'FaqBlock',
    props: ['data'],
    components: {
        Extension
    }
}
</script>

<style lang="scss">
</style>
